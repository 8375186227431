import type { Variant } from "@jobteaser/spark/components/Button"
import type { IconComponent } from "@jobteaser/spark/dist/types/Icon"
import type { ChangeEventHandler, DragEventHandler } from "react"

import { clsx } from "clsx"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"

import { Button } from "@jobteaser/spark/components/Button"
import { IconButton } from "@jobteaser/spark/components/IconButton"
import { Upload } from "@jobteaser/spark/components/Icons/Upload"
import { Text } from "@jobteaser/spark/components/Text"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { FILE_MEGA_BYTES_SIZE_MAX_LIMIT } from "@/modules/profile/constants/uploadFile"
import { checkUploadedFile } from "@/modules/profile/utils/checkUploadedFile"

import styles from "./UploadFile.module.css"

export type UploadFileErrorTypes = "max_file_size" | "empty_file" | "accepted_extensions"

type UploadFileProps = {
  iconButton?: IconComponent
  buttonDataTestid: string
  buttonLabel: string
  buttonVariant?: Variant
  inputId: string
  inputName: string
  isVisible?: boolean
  onFileChange: (file: File) => void
  onError?: (errorType: UploadFileErrorTypes) => void
  overtitle?: string
  title: string
  description?: string
  uploadButtonClassName?: string
}

export const UploadFile = forwardRef<HTMLInputElement, UploadFileProps>((props, forwardedRef) => {
  const ref = useRef<HTMLInputElement>(null)
  useImperativeHandle(forwardedRef, () => ref.current as HTMLInputElement)

  const {
    iconButton,
    buttonDataTestid,
    buttonLabel,
    buttonVariant = "secondary",
    inputId,
    inputName,
    isVisible = false,
    onFileChange,
    onError,
    overtitle,
    title,
    description,
    uploadButtonClassName,
  } = props

  const { t } = useTranslation(["shared_upload"])

  const [hasMaxSizeError, setHasMaxSizeError] = useState(false)
  const [hasMinSizeError, setHasMinSizeError] = useState(false)
  const [hasFormatError, setHasFormatError] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const handleCustomNotificationErrors = typeof onError !== "undefined"

  const handleFileSelect = (file: File): void => {
    setHasMaxSizeError(false)
    setHasMinSizeError(false)
    setHasFormatError(false)
    const { isFileNotAPdf, isFileSizeEmpty, isFileSizeExceeded } = checkUploadedFile(file)

    if (isFileSizeExceeded) {
      if (handleCustomNotificationErrors) {
        onError?.("max_file_size")
        return
      }

      setHasMaxSizeError(true)
      return
    }

    if (isFileSizeEmpty) {
      if (handleCustomNotificationErrors) {
        onError?.("empty_file")
        return
      }

      setHasMinSizeError(true)
      return
    }

    if (isFileNotAPdf) {
      if (handleCustomNotificationErrors) {
        onError?.("accepted_extensions")
        return
      }

      setHasFormatError(true)
      return
    }

    onFileChange(file)
  }

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event): void => {
    if (!event.currentTarget.files || event.currentTarget.files.length === 0) {
      return
    }

    const file = event.currentTarget.files[0]
    handleFileSelect(file)
  }

  const handleDragOver: DragEventHandler<HTMLInputElement> = (): void => {
    setIsDragging(true)
  }

  const handleDragLeave: DragEventHandler<HTMLInputElement> = (): void => {
    setIsDragging(false)
  }

  const handleDrop: DragEventHandler<HTMLInputElement> = (event): void => {
    setIsDragging(false)

    if (!event.dataTransfer.files || event.dataTransfer.files.length === 0) {
      return
    }

    const file = event.dataTransfer.files[0]
    handleFileSelect(file)
  }

  return (
    <div className={clsx(styles.container, { [styles.container___visible]: isVisible })}>
      {overtitle && (
        <Text className={styles.overtitle} variant="body1" weight="semiBold">
          {overtitle}
        </Text>
      )}
      <div className={clsx(styles.main, { [styles.main___visible]: isVisible, [styles.main___dragOver]: isDragging })}>
        {iconButton && <IconButton className={styles.icon} icon={iconButton} variant="transparent" />}
        <Text variant="title3">{title}</Text>
        {description && (
          <Text variant="body1" className={styles.description}>
            {description}
          </Text>
        )}
        <label htmlFor={inputId}>
          <Button
            onClick={() => {
              ref.current?.click()
            }}
            icon={Upload}
            variant={buttonVariant}
            className={clsx(uploadButtonClassName, styles.uploadButton)}
            data-testid={buttonDataTestid}
          >
            {buttonLabel}
          </Button>

          <input
            ref={ref}
            type="file"
            id={inputId}
            name={inputName}
            className={styles.uploadButton__input}
            accept="application/pdf"
            onChange={handleOnChange}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          />
        </label>

        {hasMaxSizeError && (
          <Text variant="caption1" weight="semiBold" className={styles.error}>
            {t("shared_upload.max_file_size_error")}
          </Text>
        )}

        {hasMinSizeError && (
          <Text variant="caption1" weight="semiBold" className={styles.error}>
            {t("shared_upload.empty_file_error")}
          </Text>
        )}

        {hasFormatError && (
          <Text variant="caption1" weight="semiBold" className={styles.error}>
            {t("shared_upload.accepted_extensions_error")}
          </Text>
        )}

        <Text variant="caption1" weight="regular" className={styles.fileInfo}>
          {t("shared_upload.max_file_size", { size: `${FILE_MEGA_BYTES_SIZE_MAX_LIMIT}MB` })} -{" "}
          {t("shared_upload.accepted_extensions", {
            formats: "PDF",
          })}
        </Text>
      </div>
    </div>
  )
})

UploadFile.displayName = "UploadFile"
