import type { FunctionComponent, PropsWithChildren } from "react"

import { Avatar } from "@jobteaser/spark/components/Avatar"
import { User } from "@jobteaser/spark/components/Icons/User"
import { Text } from "@jobteaser/spark/components/Text"

import styles from "./CardContent.module.css"

type CardContentProps = PropsWithChildren<{
  primaryText: string
  secondaryText: string
  context: "file" | "profiles"
  hasFormattedUpdateDate?: boolean
  profilePicture?: string
}>

export const CardContent: FunctionComponent<CardContentProps> = props => {
  const { primaryText, secondaryText, context, hasFormattedUpdateDate, profilePicture, children } = props

  return (
    <div className={styles.main}>
      {context === "profiles" && (
        <div className={styles.avatar}>
          <Avatar shape="rounded" size={48} fallbackIcon={User} hasError={!profilePicture}>
            <img src={profilePicture} width={48} height={48} alt="" className={styles.imgProfilePicture} />
          </Avatar>
        </div>
      )}
      <div className={styles.textContent}>
        <Text variant="body1" weight="regular">
          {primaryText}
        </Text>

        {((context === "file" && hasFormattedUpdateDate) || context === "profiles") && (
          <Text variant="body1" weight="regular" className={styles.secondaryText}>
            {secondaryText}
          </Text>
        )}
      </div>
      {children}
    </div>
  )
}
