import type { FunctionComponent } from "react"

import { IconButton } from "@jobteaser/spark/components/IconButton"
import { Eye } from "@jobteaser/spark/components/Icons/Eye"
import { PencilOutlined } from "@jobteaser/spark/components/Icons/PencilOutlined"
import { Reload } from "@jobteaser/spark/components/Icons/Reload"
import { TrashOutlined } from "@jobteaser/spark/components/Icons/TrashOutlined"

import { CardContent } from "@/modules/profile/components/CardContent/CardContent"
import { CardWrapper } from "@/modules/profile/components/CardWrapper/CardWrapper"

import styles from "./ClickableCard.module.css"

type ClickableCardProps = {
  primaryText: string
  secondaryText: string
  context: "file" | "profiles"
  href: string
  hasFormattedUpdateDate?: boolean | undefined
  onClick?: () => void
  dataTestid: string
  profilePicture?: string
  className?: string
  hasDeleteButton?: boolean
  deleteButtonLabel?: string
  onDeleteButtonClick?: () => void
  hasUpdateButton?: boolean
  updateButtonLabel?: string
  onUpdateButtonClick?: () => void
}
export const ClickableCard: FunctionComponent<ClickableCardProps> = ({
  primaryText,
  secondaryText,
  context,
  href,
  hasFormattedUpdateDate,
  onClick,
  dataTestid,
  profilePicture,
  className,
  hasDeleteButton,
  deleteButtonLabel,
  onDeleteButtonClick,
  hasUpdateButton,
  updateButtonLabel,
  onUpdateButtonClick,
}) => {
  return (
    <CardWrapper
      as="a"
      href={href}
      target="_blank"
      rel="noreferrer"
      data-testid={dataTestid}
      onClick={onClick}
      className={className}
    >
      <CardContent
        context={context}
        primaryText={primaryText}
        secondaryText={secondaryText}
        hasFormattedUpdateDate={hasFormattedUpdateDate}
        profilePicture={profilePicture}
      >
        {context === "profiles" && <PencilOutlined size={20} />}
        {context === "file" && (
          <>
            <Eye size={20} />
            {hasDeleteButton && (
              <IconButton
                aria-label={deleteButtonLabel}
                className={styles.trash}
                size="small"
                icon={TrashOutlined}
                variant="transparent"
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()

                  onDeleteButtonClick?.()
                }}
              />
            )}
            {hasUpdateButton && (
              <IconButton
                aria-label={updateButtonLabel}
                className={styles.reload}
                size="small"
                icon={Reload}
                variant="transparent"
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()

                  onUpdateButtonClick?.()
                }}
              />
            )}
          </>
        )}
      </CardContent>
    </CardWrapper>
  )
}
