import { FILE_BYTES_SIZE_MAX_LIMIT } from "@/modules/profile/constants/uploadFile"

type CheckUploadedFileParams = {
  isFileSizeExceeded: boolean
  isFileSizeEmpty: boolean
  isFileNotAPdf: boolean
}

export const checkUploadedFile = (file: File): CheckUploadedFileParams => {
  const isFileSizeExceeded = file.size > FILE_BYTES_SIZE_MAX_LIMIT
  const isFileSizeEmpty = file.size === 0
  const isFileNotAPdf = file.type !== "application/pdf" || !file.name.toLowerCase().endsWith("pdf")

  return { isFileNotAPdf, isFileSizeEmpty, isFileSizeExceeded }
}
