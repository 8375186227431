import type { ComponentPropsWithoutRef, ElementType, PropsWithChildren, ReactNode } from "react"

import { clsx } from "clsx"

import styles from "./CardWrapper.module.css"

type PolymorphicProps<E extends ElementType> = ComponentPropsWithoutRef<E> & {
  as?: E
}

type CardWrapperProps<T extends ElementType> = PolymorphicProps<T> & PropsWithChildren

export const CardWrapper = <T extends ElementType = "div">(props: CardWrapperProps<T>): ReactNode => {
  const { as, children, className, ...restProps } = props
  const Component = as || "div"
  return (
    <Component {...restProps} className={clsx(className, styles.main)}>
      {children}
    </Component>
  )
}
