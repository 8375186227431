import type { FunctionComponent } from "react"

import { useState } from "react"

import { Checkbox } from "@jobteaser/spark/components/Checkbox"
import { Tooltip } from "@jobteaser/spark/components/Tooltip"

import { ClickableCard } from "@/modules/profile/components/ClickableCard/ClickableCard"

import styles from "./NewCv.module.css"

type CommonNewCvProps = {
  cvFile: File
  updatedOnLabel: string
  updatedOnDataTestId: string
  buttonLabel?: string
  buttonDataTestId?: string
  onCheckboxChange?: (isChecked: boolean) => void
  onClickableCardClick?: () => void
  clickableCardClassName?: string
  hasDeleteButton?: boolean
  onDeleteButtonClick?: () => void
  aiCvCheckLabel?: string
  isAiValidationAllowed: boolean
  hasUpdateButton?: boolean
  updateButtonLabel?: string
  onUpdateButtonClick?: () => void
}

type DeleteButtonProps =
  | {
      hasDeleteButton: true
      onDeleteButtonClick: () => void
    }
  | {
      hasDeleteButton?: false
      onDeleteButtonClick?: never
    }

type UpdateButtonProps =
  | {
      hasUpdateButton: true
      updateButtonLabel: string
      onUpdateButtonClick: () => void
    }
  | {
      hasUpdateButton?: false
      updateButtonLabel?: never
      onUpdateButtonClick?: never
    }

type NewCvProps = CommonNewCvProps & DeleteButtonProps & UpdateButtonProps

export const NewCv: FunctionComponent<NewCvProps> = ({
  cvFile,
  updatedOnLabel,
  updatedOnDataTestId,
  buttonLabel,
  buttonDataTestId,
  onCheckboxChange,
  onClickableCardClick,
  clickableCardClassName,
  hasDeleteButton,
  onDeleteButtonClick,
  aiCvCheckLabel,
  isAiValidationAllowed,
  hasUpdateButton,
  updateButtonLabel,
  onUpdateButtonClick,
}) => {
  const [isDefaultCv, setIsDefaultCv] = useState(false)

  const url = URL.createObjectURL(cvFile)

  const handleCheckboxChange = (): void => {
    const isChecked = !isDefaultCv
    setIsDefaultCv(isChecked)

    onCheckboxChange?.(isChecked)
  }

  return (
    <>
      <input type="hidden" name="resumeType" value="new" />
      <ClickableCard
        primaryText={cvFile.name}
        secondaryText={updatedOnLabel}
        context="file"
        onClick={onClickableCardClick}
        href={url}
        hasFormattedUpdateDate
        dataTestid={updatedOnDataTestId}
        className={clickableCardClassName}
        hasDeleteButton={hasDeleteButton}
        onDeleteButtonClick={onDeleteButtonClick}
        hasUpdateButton={hasUpdateButton}
        updateButtonLabel={updateButtonLabel}
        onUpdateButtonClick={onUpdateButtonClick}
      />
      {buttonLabel && (
        <Checkbox
          value="true"
          name="resumeSetAsDefault"
          className={styles.setCvAsDefault}
          onChange={handleCheckboxChange}
          data-testid={buttonDataTestId}
          checked={isDefaultCv}
          area-label={aiCvCheckLabel}
        >
          {buttonLabel}

          {isAiValidationAllowed && (
            <Tooltip
              content={aiCvCheckLabel ?? ""}
              className={styles.setCvAsDefaultTooltip}
              defaultTriggerAriaLabel={aiCvCheckLabel ?? ""}
            />
          )}
        </Checkbox>
      )}
    </>
  )
}
