import type { Resume } from "@/modules/profile/api/types"
import type { FunctionComponent } from "react"

import { ClickableCard } from "@/modules/profile/components/ClickableCard/ClickableCard"

type CommonCurrentCvProps = {
  onClick?: () => void
  updatedOnLabel: string
  updatedOnDataTestId: string
  formattedUpdatedAtDate: string | undefined
  userResume: Resume
  clickableCardClassName?: string
  hasDeleteButton?: boolean
  deleteButtonLabel?: string
  onDeleteButtonClick?: () => void
  hasUpdateButton?: boolean
  updateButtonLabel?: string
  onUpdateButtonClick?: () => void
}

type DeleteButtonProps =
  | {
      hasDeleteButton: true
      deleteButtonLabel: string
      onDeleteButtonClick: () => void
    }
  | {
      hasDeleteButton?: false
      deleteButtonLabel?: never
      onDeleteButtonClick?: never
    }

type UpdateButtonProps =
  | {
      hasUpdateButton: true
      updateButtonLabel: string
      onUpdateButtonClick: () => void
    }
  | {
      hasUpdateButton?: false
      updateButtonLabel?: never
      onUpdateButtonClick?: never
    }

type CurrentCvProps = CommonCurrentCvProps & DeleteButtonProps & UpdateButtonProps

export const CurrentCv: FunctionComponent<CurrentCvProps> = ({
  onClick,
  updatedOnLabel,
  updatedOnDataTestId,
  formattedUpdatedAtDate,
  userResume,
  clickableCardClassName,
  hasDeleteButton,
  deleteButtonLabel,
  onDeleteButtonClick,
  hasUpdateButton,
  updateButtonLabel,
  onUpdateButtonClick,
}) => {
  const { id, fileName, downloadUrl } = userResume

  if (!id || !fileName || !downloadUrl) {
    return null
  }

  return (
    <>
      <input type="hidden" name="resumeId" value={id} />
      <input type="hidden" name="resumeType" value="current" />
      <ClickableCard
        primaryText={fileName}
        secondaryText={updatedOnLabel}
        context="file"
        href={downloadUrl}
        onClick={() => onClick?.()}
        hasFormattedUpdateDate={!!formattedUpdatedAtDate}
        dataTestid={updatedOnDataTestId}
        className={clickableCardClassName}
        hasDeleteButton={hasDeleteButton}
        deleteButtonLabel={deleteButtonLabel}
        onDeleteButtonClick={onDeleteButtonClick}
        hasUpdateButton={hasUpdateButton}
        updateButtonLabel={updateButtonLabel}
        onUpdateButtonClick={onUpdateButtonClick}
      />
    </>
  )
}
