import type { TFunction } from "@/modules/i18n/components/types"
import type { I18nLocale } from "@/modules/i18n/types"

import RelativeTime from "@yaireo/relative-time"
import { isSameDay, isValid } from "date-fns"

import { unicodeSpacesToNonBreakingSpace } from "@/modules/format/string/unicodeSpacesToNonBreakingSpace"
import { DEFAULT_LOCALE } from "@/modules/locales/constants"
import { getLocaleTerritoryCode } from "@/modules/locales/getLocaleTerritoryCode"

export const DATE_FORMATS: Record<string, Intl.DateTimeFormatOptions> = {
  DEFAULT_DATE: {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  },
  FULL_DATE: {
    day: "numeric",
    month: "long",
    weekday: "long",
  },
  FULL_DATETIME: {
    day: "numeric",
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    month: "long",
    weekday: "long",
  },
  LIGHT_DATE: {
    dateStyle: "long",
  },
  MONTH_YEAR: {
    month: "long",
    year: "numeric",
  },
  SHORT_MONTH_YEAR: {
    month: "short",
    year: "numeric",
  },
  // FIRST_DAY_OF_MONTH: {}, // Use Date.setDate(1)
  TIME: {
    timeStyle: "short",
  }, // Default behavior
  // DEFAULT_DATE_FNS: {}, // Use Date.toISOString()
}

const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone

export type GetRemainingTimeOptions = {
  date: Date | number | string
  locale?: I18nLocale
  reverse?: boolean
  timeZone?: string
}

export type GetDateOptions = {
  date?: Date | number
  dateFormat?: Intl.DateTimeFormatOptions
  locale?: I18nLocale
  timeZone?: string
}

export const getRemainingTime = (options: GetRemainingTimeOptions): string | undefined => {
  const { date, locale = DEFAULT_LOCALE, reverse = false, timeZone = DEFAULT_TIMEZONE } = options
  const dateToFormatDistance = new Date(date)

  if (!isValid(dateToFormatDistance)) {
    console.warn("Invalid date format")
    return
  }

  const dates: [Date, Date] = [new Date(), dateToFormatDistance]
  const args = reverse ? (dates.reverse() as [Date, Date]) : dates

  // eslint-disable-next-line consistent-return
  return new RelativeTime({ locale, options: { timeZone } }).from(...args)
}

export const getDate = (options: GetDateOptions): string | undefined => {
  const {
    date = new Date(),
    dateFormat = DATE_FORMATS.DEFAULT_DATE,
    locale = DEFAULT_LOCALE,
    timeZone = DEFAULT_TIMEZONE,
  } = options
  const dateToFormat = new Date(date)

  if (!isValid(dateToFormat)) {
    console.warn("Invalid date format")
    return
  }

  // eslint-disable-next-line consistent-return
  return unicodeSpacesToNonBreakingSpace(
    new Intl.DateTimeFormat(getLocaleTerritoryCode(locale), {
      ...dateFormat,
      timeZone,
    }).format(dateToFormat)
  )
}

/**
 * Next SSR uses JSON.stringify to parse props. This will convert the Date type to String.
 * We need to reparse string to date to fix any date issues.
 */
export const safeDate = (date: Date | string): Date => (typeof date === "string" ? new Date(date) : date)

export const getLocalISOString = (rawDate: Date): string => {
  const date = safeDate(rawDate)
  const ONE_MIN_IN_MS = 60 * 1_000
  const timezoneOffsetInMin = date.getTimezoneOffset()
  const offsetAbs = Math.abs(timezoneOffsetInMin)
  const dateIsoString = new Date(date.getTime() - timezoneOffsetInMin * ONE_MIN_IN_MS).toISOString()
  // Cut off the trailing Z from the ISO String so that JS doesn't consider it in UTC.
  const dateWithoutUTC = dateIsoString.slice(0, -1)
  const timezoneOperator = timezoneOffsetInMin > 0 ? "-" : "+"
  const timezoneHour = String(Math.floor(offsetAbs / 60)).padStart(2, "0")
  const timezoneMin = String(offsetAbs % 60).padStart(2, "0")

  return `${dateWithoutUTC}${timezoneOperator}${timezoneHour}:${timezoneMin}`
}

export const endOfDay = (date: Date): Date => new Date(new Date(date).setHours(23, 59, 59, 999))
export const beginningOfDay = (date: Date): Date => new Date(new Date(date).setHours(0, 0, 0, 0))

export const beginningOfMonth = (rawDate: Date): Date => {
  const date = safeDate(rawDate)
  const year = date.getFullYear()
  const month = date.getMonth()
  const firstDay = 1
  const firstDayOfMonth = new Date(year, month, firstDay)

  return beginningOfDay(firstDayOfMonth)
}

/**
 * Format a beginning date and an ending date to represent a range.
 * Depending on start date and end date are the same day,
 * output is : "Tues. 29 April, 10 am - 7 pm"
 * or : "From Tues. 18 April to Tues. 02 May"
 */
type formatRangeParams = {
  startDate: string | Date
  endDate: string | Date
  locale: I18nLocale
  t: TFunction<"shared_date_time">
  timeZone?: string
}
export const formatRange = (params: formatRangeParams): string => {
  const { startDate, endDate, locale, t, timeZone = DEFAULT_TIMEZONE } = params
  const safeStartDate = safeDate(startDate)
  const safeEndDate = safeDate(endDate)

  const areDatesOnSameDay = isSameDay(safeStartDate, safeEndDate)

  if (areDatesOnSameDay) {
    return unicodeSpacesToNonBreakingSpace(
      new Intl.DateTimeFormat(getLocaleTerritoryCode(locale), {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        month: "long",
        timeZone,
        weekday: "short",
      }).formatRange(safeStartDate, safeEndDate)
    )
  }

  return t("shared_date_time.from_to", {
    endDate: unicodeSpacesToNonBreakingSpace(
      new Intl.DateTimeFormat(getLocaleTerritoryCode(locale), {
        day: "numeric",
        month: "long",
        timeZone,
        weekday: "short",
      }).format(safeEndDate)
    ),
    startDate: unicodeSpacesToNonBreakingSpace(
      new Intl.DateTimeFormat(getLocaleTerritoryCode(locale), {
        day: "numeric",
        month: "long",
        timeZone,
        weekday: "short",
      }).format(safeStartDate)
    ),
  })
}

export const ISO_DATE_REGEXP = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/
