import type { I18nLocale } from "@/modules/i18n/types"

import { getResultFromResponse } from "@/modules/apiRequests/handlers"
import { handleFetchError } from "@/modules/fetch/errorHandler"
import { fetcher } from "@/modules/fetch/fetcher"
import { toCamelCaseKeys } from "@/modules/format/object/toCamelCaseKeys"
import { UPLOAD_RESUME_ENDPOINT } from "@/modules/profile/api/constants"

const LIMIT_RESPONSE_TIME = 10000

export type UploadResumeParams = {
  file: File
  hostname: string
  locale: I18nLocale
  method?: "POST" | "PUT"
  userToken: string
  isAiValidationAllowed?: boolean
}

export type UploadResponse = {
  resumeId: string
  resumeUrl: string
}

export const uploadResume = async (params: UploadResumeParams): Promise<UploadResponse> => {
  const { hostname, method = "POST", file, locale, userToken, isAiValidationAllowed } = params

  const body = new FormData()

  body.append("resume", file)
  body.append("is_ai_validation_allowed", isAiValidationAllowed ? "true" : "false")

  return fetcher(UPLOAD_RESUME_ENDPOINT, {
    hostname,
    requestInit: {
      body,
      headers: {
        "Accept-Language": locale,
        Authorization: `Bearer ${userToken}`,
      },
      method,
      signal: AbortSignal.timeout(LIMIT_RESPONSE_TIME),
    },
    serviceDomain: "MONOLITH_API",
    withDefaultContentType: false,
  })
    .then(
      getResultFromResponse<UploadResponse>({
        transformResult: toCamelCaseKeys<UploadResponse>,
        useAPIErrorResponse: true,
      })
    )
    .catch(handleFetchError(UPLOAD_RESUME_ENDPOINT))
}
