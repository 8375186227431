export const PROFILE_QUERY_KEY = "profile"
export const PROFILE_ENDPOINT = "/api/profiles/v1/profiles"
export const UPDATE_EXPERIENCES_ENDPOINT = "/api/profiles/v1/profiles/experiences"
export const CONTRACT_TYPES_ENDPOINT = "/api/profiles/v1/static_data/contract_types"
export const UPLOAD_RESUME_ENDPOINT = "/api/profiles/v1/resume"
export const SCHOOL_INFORMATION_ENDPOINT = "/api/profiles/v1/static_data/school_information"
export const GET_AVAILABLE_SCHOOL_YEARS_ENDPOINT = "/v1/school-years"
export const GET_USER_SCHOOL_YEAR_ENDPOINT = "/v1/user/school-year"
export const UPDATE_USER_SCHOOL_YEAR_ENDPOINT = "/v1/user/school-year"
export const UPDATE_APPLICANT_ATTRIBUTES_IF_EMPTY_ENDPOINT = "/profile/update_applicant_attributes_if_empty"
export const UPDATE_LANGUAGES_ENDPOINT = "/api/profiles/v1/profiles/languages"
